.login-container {
  display: flex;
  -webkit-display: box;
  -moz-display: box;
  -ms-display: flexbox;
  -webkit-display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  padding: 2%;
  margin: 0;
  margin-bottom: 20px;
  position: relative;
}

.cidekic-logo-login {
  height: 110px;
  width: auto;
  margin-bottom: 24px;
  background-color: white;
}

.form-content {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 350px;
}

.login-contactus {
  text-align: center;
  margin: 10px 30px;
  color: var(--accent-color) !important;
}

.login_box {
  border: 1px solid lightgray;
  padding: 20px;
  margin-top: 30px;
  border-radius: 6px;
}

.reg-user-login {
  color: var(--base-text);
  margin-bottom: 20px;
  margin-top: 50px;
}

.login-user-input {
  width: 100%;
  height: 60px;
  margin-bottom: 15px;
  padding: 10px 20px;
  border-radius: 10px;
  background-color: var(--mid-fill);
  border: 0;
  color: var(--base-text);
  -webkit-user-select: text; /* Chrome, Opera, Safari */
  -moz-user-select: text; /* Firefox 2+ */
  -ms-user-select: text; /* IE 10+ */
  user-select: text; /* Standard syntax */
}

.login-user-error {
  width: 100%;
  height: 60px;
  margin-bottom: 15px;
  padding: 10px 20px;
  border-radius: 10px;
  background-color: var(--mid-fill);
  border: 2px solid var(--red);
  color: var(--base-text);
  -webkit-user-select: text; /* Chrome, Opera, Safari */
  -moz-user-select: text; /* Firefox 2+ */
  -ms-user-select: text; /* IE 10+ */
  user-select: text; /* Standard syntax */
}

.login-labels {
  font-size: 16px;
  margin: 5px;
  color: var(--base-text);
}

.reset-label {
  font-size: 14px;
  margin-bottom: 10px;
}

.forget-info {
  margin-left: 45%;
  font-size: 12px;
  color: var(--red);
  cursor: pointer;
}

.signup-message {
  text-align: center;
  color: var(--accent-color);
  margin-top: 20px;
}

.signup-msg1 {
  color: var(--base-text);
  text-align: center;
  margin-top: 20px;
}

.signup-msg2 {
  color: var(--accent-color);
  margin-left: 7px;
  cursor: pointer;
}

.signup-msg3 {
  color: var(--accent-color);
  margin-left: 7px;
  cursor: pointer;
}

.login-btn-recipe {
  width: 100%;
  height: 60px;
  border-radius: 6px;
  background-color: var(--accent-color);
  font-size: 18px;
  border: none;
  margin-right: 20px;
  color: var(--base-text);
  cursor: pointer;
}

.login-remember-me {
  color: var(--base-text);
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 22px;
}

.login-remember-me:focus {
  outline: 0;
}

.incorrect-login {
  padding: 10px;
  margin-bottom: 10px;
  background-color: #ffc1a8;
  border-radius: 6px;
  display: flex;
}

.form-style {
  margin: 0 79px;
}

.pwd {
  margin: -5px 79px 0px 79px;
}

.login-btn {
  text-align: center;
}

.checkbx {
  position: relative;
  margin-left: 10px;
}

.checkbx .ant-checkbox-inner {
  background-color: var(--mid-fill) !important;
  border: 0px !important;
}

.wrapper-login {
  padding: 5px;
  max-width: 960px;
  width: 95%;
  margin: 20px auto;
}

.header-login {
  padding: 0 15px;
  margin-top: 20px;
}

.columns-login {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin: 5px 0;
}

.column-login1 {
  flex: 1 1;
  margin: 2px;
  padding: 15px;
  text-align: center;
  color: var(--base-text);
}

.column-login1 :first-child {
  margin-left: 0;
}
.column-login1 :last-child {
  margin-right: 0;
}

footer {
  padding: 0 15px;
  text-align: center;
}

.login-caps {
  color: var(--base-text);
}

.login-title {
  color: var(--base-text);
  text-align: center;
}

.login-line {
  height: 1px;
  margin: 3% 0 0;
  border: solid 1px var(--main-borders);
  width: 100%;
  position: absolute;
  top: 125px;
}

.login-line-bottom {
  height: 1px;
  margin: 3%;
  border: solid 1px var(--main-borders);
  width: 100%;
  position: absolute;
  top: -30px;
}

.login-ic {
  height: 60px;
  width: 75px;
  margin-bottom: 7px;
}

.copyrt-ic {
  height: 30px;
  width: 30px;
  padding-bottom: 3px;
}

.locked-alert {
  padding-bottom: 12px;
  height: 32px;
  margin-right: 4px;
}

@media screen and (max-width: 680px) {
  .columns-login .column-login1 {
    flex-basis: 100%;
    margin: 0 0 5px 0;
  }
}
