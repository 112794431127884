.login-form-container .ant-form-item-label label {
  color: #bfbfbf;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 17px;
  margin-bottom: -3px;
}

.login-form-container .ant-form-item {
  margin-bottom: 12px;
}

.activate-box {
  border: 1px solid #f2f2f2;
  border-radius: 6px;
  box-shadow: 0 2px 24px 6px rgba(0, 0, 0, 0.05);
  min-height: 501px;
  width: 500px;
}

@media only screen and (max-width: 699px) {
  .activate-box {
    width: 320px;
  }
}

.reset-form-header {
  color: var(--base-text);
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 17px;
  margin: 10px 0;
}

.reset-form-header-name {
  color: #00274c;
  font-family: Lato;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 17px;
  margin-bottom: 2px;
}
