.reset-password-form-container {
  padding: 10px;
}

.reset-header {
  font-size: 18px;
  margin: 20px 5px;
}

.reset-btn {
  text-align: center;
}
